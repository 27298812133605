import { useIonRouter } from "@ionic/vue";
import { onUnmounted, Ref, watch } from "vue";

// Track active modals
export const activeModals: string[] = [];

export function useModalBackButton(
  isOpen: Ref<boolean>,
  onClose: () => void,
  modalId: string = "modal",
) {
  const router = useIonRouter();

  const handleBackButton = (event: PopStateEvent) => {
    // Only handle if this is the top-most modal
    if (isOpen.value && activeModals[activeModals.length - 1] === modalId) {
      event.preventDefault();
      onClose();
    }
  };

  watch(
    () => isOpen.value,
    (newIsOpen) => {
      if (newIsOpen) {
        // Add to modal stack
        activeModals.push(modalId);
        // Only push state if this is the first modal
        if (activeModals.length === 1) {
          history.pushState({ modalId }, "", window.location.href);
        }
        window.addEventListener("popstate", handleBackButton);
      } else {
        // Remove from modal stack
        const index = activeModals.indexOf(modalId);
        if (index > -1) {
          activeModals.splice(index, 1);
        }
        window.removeEventListener("popstate", handleBackButton);

        // Only go back if this was the last modal in stack
        if (activeModals.length === 0 && history.state?.modalId === modalId) {
          router.back();
        }
      }
    },
  );

  onUnmounted(() => {
    const index = activeModals.indexOf(modalId);
    if (index > -1) {
      activeModals.splice(index, 1);
    }
    window.removeEventListener("popstate", handleBackButton);
  });

  return {
    activeModals,
  };
}
