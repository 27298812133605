<template>
  <ion-app>
    <transition name="show-offline">
      <div v-if="!onLine" class="absolute z-50 w-full p-5">
        <div class="rounded-xl border bg-red-500 px-4 py-3 text-white">
          {{ $t("offlinealert") }}
        </div>
      </div>
    </transition>
    <Toast ref="toast" />
    <ConfirmDialog ref="confirmDialog" class="w-full sm:w-auto" />
    <ion-split-pane content-id="main-content" when="false">
      <component :is="menuComponent" v-if="menuComponent" class="w-auto" />
      <ion-router-outlet id="main-content" :key="route.fullPath">
      </ion-router-outlet>
    </ion-split-pane>
  </ion-app>
</template>

<script setup lang="ts">
import {
  IonApp,
  IonRouterOutlet,
  IonSplitPane,
  useBackButton,
  useIonRouter,
} from "@ionic/vue";
import ConfirmDialog from "primevue/confirmdialog";
import Toast from "primevue/toast";
import { onBeforeUnmount, onMounted } from "vue";
import { useRoute } from "vue-router";

import MenuGanadero from "./components/Menus/MenuGanadero.vue";
import MenuOperador from "./components/Menus/MenuOperador.vue";
import MenuPartial from "./components/Menus/MenuPartial.vue";
import { activeModals } from "./composables/useModalBackButton";

const route = useRoute();
const userStore = useUserStore();

const ionRouter = useIonRouter();

// Back button handler
onMounted(() => {
  useBackButton(10, async () => {
    if (activeModals.length > 0) {
      const popStateEvent = new PopStateEvent("popstate", {
        state: history.state,
      });
      window.dispatchEvent(popStateEvent);
    } else {
      ionRouter.back();
    }
  });
});

const menuComponent = computed(() => {
  if (!userStore.user.role) {
    return null;
  }
  if (userStore.isRancher) {
    return MenuGanadero;
  } else if (userStore.isOperator) {
    return MenuOperador;
  } else if (userStore.isPartial) {
    return MenuPartial;
  } else {
    return null;
  }
});

const onLine = ref(navigator.onLine);
const showBackOnline = ref(false);

const updateOnlineStatus = (e: Event) => {
  const { type } = e;
  onLine.value = type === "online";
};

watch(onLine, (v) => {
  if (v) {
    showBackOnline.value = true;
    setTimeout(() => {
      showBackOnline.value = false;
    }, 1000);
  }
});

// Lifecycle hooks
onMounted(() => {
  window.addEventListener("online", updateOnlineStatus);
  window.addEventListener("offline", updateOnlineStatus);
});

onBeforeUnmount(() => {
  window.removeEventListener("online", updateOnlineStatus);
  window.removeEventListener("offline", updateOnlineStatus);
});
</script>

<style>
#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.show-offline-enter-active,
.show-offline-leave-active {
  transform: translateY(0px);
  transition: all 0.2s;
}

.show-offline-enter,
.show-offline-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}
</style>
