import { ResponseType } from "axios";

import axios from "@/axios-instance";

import AuthService from "./auth.service";

const API_URL = import.meta.env.VITE_URL_API;

class ApiService {
  post(path, formData) {
    const config = {
      headers: AuthService.authHeaderCognito(),
      "Content-Type": "application/json",
    };
    return axios.post(API_URL + path, formData, config);
  }

  postMultipart(path, formData) {
    const config = {
      headers: AuthService.authHeaderCognito(),
      "Content-Type": "multipart/form-data",
    };
    return axios.post(API_URL + path, formData, config);
  }

  get(path, params?) {
    const config = {
      headers: AuthService.authHeaderCognito(),
      params: params || {},
    };
    return axios.get(API_URL + path, config);
  }

  getBlob(path: string, params?) {
    const config = {
      headers: AuthService.authHeaderCognito(),
      responseType: "blob" as ResponseType,
      params: params || {},
    };
    return axios.get(API_URL + path, config);
  }

  async request(path, responseType: ResponseType) {
    const config = {
      url: API_URL + path,
      headers: AuthService.authHeaderCognito(),
      responseType: responseType,
    };
    return await axios.request(config);
  }

  put(path, formData) {
    const config = {
      baseURL: API_URL,
      headers: AuthService.authHeaderCognito(),
    };
    return axios.put(path, formData, config);
  }

  delete(path, params) {
    const config = {
      headers: AuthService.authHeaderCognito(),
      params: params || {},
    };
    return axios.delete(API_URL + path, config);
  }

  deleteWithBody(path, data) {
    const config = {
      headers: {
        ...AuthService.authHeaderCognito(),
        "Content-Type": "application/json",
      },
      data: data || {},
    };
    return axios.delete(API_URL + path, config);
  }
}

export default new ApiService();
