import { defineStore } from "pinia";

import { RancherTypes, UserRoles, UserStatus } from "@/constants/constants";
import apiService from "@/core/services/api.service";
import { Device, User } from "@/types/types";

import { useTemplateStore } from "./TemplateStore";

export const useUserStore = defineStore({
  id: "userStore",
  persist: true,
  state: () => ({
    user: {} as User,
    lang: "es",
    token: "",
    hasIban: undefined as boolean | undefined,
    preferredLayout: "list",
    isLoggingOut: false,
    devices: [] as Device[],
  }),
  actions: {
    async fetchUser() {
      return apiService
        .get("/usuarios/actual")
        .then((response) => {
          this.user = response.data;
        })
        .catch((error) => {
          console.error("Error fetching user:", error);
        });
    },
    async checkAndFetchUser() {
      if (!this.user || Object.keys(this.user).length === 0) {
        await this.fetchUser();
      }
    },
    setToken(token) {
      this.token = token;
    },
    async fetchHasIban() {
      try {
        return apiService.get("/iban/has-valid-iban").then((response) => {
          this.hasIban = response.data;
        });
      } catch (error) {
        console.error("Error fetching Iban:", error);
      }
    },
    async checkAndFetchHasIban() {
      if (this.hasIban === undefined) {
        await this.fetchHasIban();
      }
    },
  },
  getters: {
    isRancher() {
      return RancherTypes.includes(this.user.role);
    },
    isIndividualRancher() {
      return this.user.role === UserRoles.INDIVIDUAL_RANCHER;
    },
    isCompanyRancher() {
      return this.user.role === UserRoles.COMPANY_RANCHER;
    },
    isOperator() {
      return this.user.role === UserRoles.OPERATOR;
    },
    isRancherFixedContractAllowed(): boolean {
      const templateStore = useTemplateStore();
      return templateStore.hasFixedPriceTemplates && this.isRancher;
    },
    isRancherRateContractAllowed(): boolean {
      const templateStore = useTemplateStore();
      return templateStore.hasRateTemplates && this.isRancher;
    },
    isRancherContractAllowed(): boolean {
      return (
        this.isRancherFixedContractAllowed || this.isRancherRateContractAllowed
      );
    },
    isOperatorFixedContractAllowed(): boolean {
      const templateStore = useTemplateStore();
      return templateStore.hasFixedPriceTemplates && this.isOperator;
    },
    isOperatorRateContractAllowed(): boolean {
      const templateStore = useTemplateStore();
      return templateStore.hasRateTemplates && this.isOperator;
    },
    isOperatorContractAllowed(): boolean {
      return (
        this.isOperatorFixedContractAllowed ||
        this.isOperatorRateContractAllowed
      );
    },
    isActive(): boolean {
      return this.user.estado === UserStatus.ACTIVE;
    },
    isInactive(): boolean {
      return this.user.estado === UserStatus.INACTIVE;
    },
    isPartial(): boolean {
      return this.user.role === UserRoles.PARTIAL;
    },
  },
});
