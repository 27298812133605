import { App } from "@capacitor/app";
import { Capacitor } from "@capacitor/core";
import { Device } from "@capacitor/device";
import { LocalNotifications } from "@capacitor/local-notifications";
import {
  FirebaseMessaging,
  GetTokenOptions,
} from "@capacitor-firebase/messaging";
import { ref } from "vue";

import apiService from "@/core/services/api.service";
import router from "@/router";
import { SnsPayload } from "@/types/types";

export function useFirebaseMessaging() {
  const permissionGranted = ref<boolean>(false);
  const fcmToken = ref<string | null>(null);

  const requestPermissions = async () => {
    await FirebaseMessaging.requestPermissions();
  };

  const deleteDeviceUuid = async () => {
    const deviceUuid = (await Device.getId()).identifier;
    const formData = new FormData();
    formData.append("deviceUuid", deviceUuid);
    try {
      await apiService.deleteWithBody("/notification-service/delete/uuid", {
        deviceUuid,
      });
    } catch (error) {
      console.error("Error deleting device:", error);
    }
  };

  const disableDevice = async () => {
    const formData = new FormData();
    formData.append("deviceToken", fcmToken.value);
    try {
      await apiService.post("/notification-service/device/disable", {
        deviceToken: fcmToken.value,
      });
      console.log("Device disabled successfully");
    } catch (error) {
      console.error("Error disabling device:", error);
    }
  };

  const init = async () => {
    if (Capacitor.getPlatform() !== "web") {
      await requestPermissions();

      FirebaseMessaging.createChannel({
        id: "notification_channel",
        name: "Notification Channel",
        description: "Notification Channel",
        importance: 3,
        visibility: 1,
      });
    }

    FirebaseMessaging.removeAllListeners().then(async () => {
      getToken();
      addNotificationReceivedListener();

      addNotificationActionPerformedListener();
    });

    if (Capacitor.getPlatform() !== "web") {
      // Listen for local notification actions
      LocalNotifications.addListener(
        "localNotificationActionPerformed",
        (event) => {
          const url = event.notification.extra.url;
          if (url && url.trim() !== "") {
            router.push(url);
          } else {
            console.error("No valid URL found in notification data");
          }
        },
      );
    }

    if (Capacitor.getPlatform() === "web") {
      navigator.serviceWorker.addEventListener("message", (event: any) => {
        const url = event.data.data.url;

        const notification = new Notification(event.data.notification.title, {
          body: event.data.notification.body,
        });

        // eslint-disable-next-line no-unused-vars
        notification.onclick = (event) => {
          if (url && url.trim() !== "") {
            router.push(url);
          } else {
            console.error("No valid URL found in notification data");
          }
        };
      });
    }
  };

  const scheduleLocalNotification = async (
    title: string,
    body: string,
    url: string,
  ) => {
    // Generate a random integer ID within the range of valid Java int values
    const notificationId = Math.floor(Math.random() * 100000);

    await LocalNotifications.schedule({
      notifications: [
        {
          title,
          body,
          id: notificationId, // Unique ID for the notification
          schedule: { at: new Date(Date.now() + 1000) }, // Schedule to show immediately
          sound: null,
          attachments: null,
          actionTypeId: "",
          extra: { url }, // Pass the URL in the extra data
        },
      ],
    });
  };

  const addNotificationReceivedListener = async () => {
    FirebaseMessaging.addListener("notificationReceived", (event) => {
      const { title, body, data } = event.notification;
      const url = data.url;

      if (Capacitor.getPlatform() !== "web") {
        scheduleLocalNotification(title, body, url);
      }
    });
  };

  const addNotificationActionPerformedListener = async () => {
    FirebaseMessaging.addListener("notificationActionPerformed", (event) => {
      const url = event.notification.data.url;
      if (url && url.trim() !== "") {
        router.push(url);
      } else {
        console.error("No valid URL found in notification data");
      }
    });
  };

  const getToken = async () => {
    const options: GetTokenOptions = {
      vapidKey: import.meta.env.VITE_APP_FIREBASE_VAPID_KEY as string,
    };

    if (Capacitor.getPlatform() === "web") {
      // // Unregister existing service worker if any
      // const registration = await navigator.serviceWorker.getRegistration(
      //   "/firebase-messaging-sw.js",
      // );
      // if (registration) {
      //   await registration.unregister();
      // }

      options.serviceWorkerRegistration =
        await navigator.serviceWorker.register("/firebase-messaging-sw.js");
    }
    const { token: firebaseToken } = await FirebaseMessaging.getToken(options);
    fcmToken.value = firebaseToken;
    sendTokenToServer();
  };

  const sendTokenToServer = async () => {
    if (fcmToken.value) {
      try {
        let appVersion = "";
        if (Capacitor.getPlatform() === "android") {
          const appInfo = await App.getInfo();
          appVersion = appInfo.version;
        }
        const deviceInfo = await Device.getInfo();
        const payload: SnsPayload = {
          deviceToken: fcmToken.value,
          deviceUUID: (await Device.getId()).identifier,
          devicePlatform: Capacitor.getPlatform(),
          deviceModel: deviceInfo.model,
          deviceOsVersion: deviceInfo.osVersion,
          deviceWebViewVersion: deviceInfo.webViewVersion,
          deviceAppVersion: appVersion,
        };
        await apiService.post("/notification-service/device", payload);
      } catch (error) {
        console.error("Error sending token to server:", error);
      }
    } else {
      console.error("No token available to send to server");
    }
  };

  return {
    permissionGranted,
    requestPermissions,
    deleteDeviceUuid,
    disableDevice,
    fcmToken,
    init,
    getToken,
  };
}
