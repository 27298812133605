import "unfonts.css";
import "./style.css";

import { createPinia } from "pinia";
import piniaPluginPersistedState from "pinia-plugin-persistedstate";
import { createApp } from "vue";

import App from "./App.vue";
import i18n from "./plugins/i18n";
import router from "./router";
const pinia = createPinia();
pinia.use(piniaPluginPersistedState);

import { localize, setLocale } from "@vee-validate/i18n";
import es from "@vee-validate/i18n/dist/locale/es.json";
import { email, required } from "@vee-validate/rules";
import { configure, defineRule } from "vee-validate";
// Vee Validate rules - reglas
defineRule("required", required);
defineRule("email", email);

configure({
  validateOnInput: true,
  generateMessage: localize({
    es,
  }),
});
// Locale inicial
setLocale("es");

import { Capacitor } from "@capacitor/core";
import { initializeApp } from "firebase/app";
// Firebase configuration
const firebaseConfig = {
  apiKey: import.meta.env.VITE_APP_FIREBASE_API_KEY as string,
  authDomain: import.meta.env.VITE_APP_FIREBASE_AUTH_DOMAIN as string,
  projectId: import.meta.env.VITE_APP_FIREBASE_PROJECT_ID as string,
  storageBucket: import.meta.env.VITE_APP_FIREBASE_STORAGE_BUCKET as string,
  messagingSenderId: import.meta.env
    .VITE_APP_FIREBASE_MESSAGING_SENDER_ID as string,
  appId: import.meta.env.VITE_APP_FIREBASE_APP_ID as string,
  measurementId: import.meta.env.VITE_APP_MEASUREMENT_ID as string,
  vapidKey: import.meta.env.VITE_APP_FIREBASE_VAPID_KEY as string,
};

if (!Capacitor.isNativePlatform()) {
  initializeApp(firebaseConfig);
}

import { defineCustomElements } from "@ionic/pwa-elements/loader";
import { IonicVue } from "@ionic/vue";
defineCustomElements(window);

import { createHead } from "@unhead/vue";
import BadgeDirective from "primevue/badgedirective";
import PrimeVue from "primevue/config";
import ConfirmationService from "primevue/confirmationservice";
import DialogService from "primevue/dialogservice";
import Ripple from "primevue/ripple";
import ToastService from "primevue/toastservice";

import Lara from "./presets/lara";

const app = createApp(App);

const head = createHead();

app.directive("badge", BadgeDirective);
app.directive("ripple", Ripple);
app.use(ConfirmationService);
app.use(ToastService);
app.use(DialogService);

app
  .use(IonicVue, {
    mode: "md",
  })
  .use(router)
  .use(pinia)
  .use(head)
  .use(i18n);

app.use(PrimeVue, {
  ripple: true,
  unstyled: true,
  pt: Lara,
  locale: {
    accept: "Sí",
    addRule: "Agregar regla",
    am: "AM",
    apply: "Aplicar",
    cancel: "Cancelar",
    choose: "Escoger",
    chooseDate: "Elige fecha",
    chooseMonth: "Elige el mes",
    chooseYear: "Elige Año",
    clear: "Limpiar",
    completed: "Terminado",
    contains: "Contenga",
    custom: "Personalizar",
    dateAfter: "Fecha después de",
    dateBefore: "Fecha antes de",
    dateFormat: "dd/mm/yy",
    dateIs: "Fecha igual a",
    dateIsNot: "Fecha diferente a",
    dayNames: [
      "Domingo",
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sábado",
    ],
    dayNamesMin: ["D", "L", "M", "M", "J", "V", "S"],
    dayNamesShort: ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"],
    emptyFilterMessage: "Sin opciones disponibles",
    emptyMessage: "No se han encontrado resultados",
    emptySearchMessage: "Sin opciones disponibles",
    emptySelectionMessage: "Ningún artículo seleccionado",
    endsWith: "Termine con",
    equals: "Igual a",
    fileSizeTypes: ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
    filter: "Filtrar",
    firstDayOfWeek: 1,
    gt: "Mayor que",
    gte: "Mayor o igual a",
    lt: "Menor que",
    lte: "Menor o igual a",
    matchAll: "Coincidir todo",
    matchAny: "Coincidir con cualquiera",
    medium: "Medio",
    of: "De",
    monthNames: [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ],
    monthNamesShort: [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dic",
    ],
    nextDecade: "Próxima década",
    nextHour: "próxima hora",
    nextMinute: "Siguiente minuto",
    nextMonth: "Próximo mes",
    nextSecond: "Siguiente segundo",
    nextYear: "El próximo año",
    noFilter: "Sin filtro",
    notContains: "No contenga",
    notEquals: "Diferente a",
    now: "Ahora",
    passwordPrompt: "Escriba una contraseña",
    pending: "Pendiente",
    pm: "PM",
    prevDecade: "Década anterior",
    prevHour: "Hora anterior",
    prevMinute: "Minuto anterior",
    prevMonth: "Mes anterior",
    prevSecond: "Anterior Segundo",
    prevYear: "Año anterior",
    reject: "No",
    removeRule: "Eliminar regla",
    searchMessage: "{0} resultados están disponibles",
    selectionMessage: "{0} elementos seleccionados",
    showMonthAfterYear: false,
    startsWith: "Comience con",
    strong: "Fuerte",
    today: "Hoy",
    upload: "Subir",
    weak: "Débil",
    weekHeader: "Sem",
    aria: {
      cancelEdit: "Cancelar editado",
      close: "Cerrar",
      collapseLabel: "Colapsar",
      collapseRow: "Reducir Fila",
      editRow: "Editar fila",
      expandLabel: "Expandir",
      expandRow: "Expandir Fila",
      falseLabel: "Falso",
      filterConstraint: "Restricción de filtro",
      filterOperator: "Operador de filtro",
      firstPageLabel: "Primera Página",
      gridView: "Vista de cuadrícula",
      hideFilterMenu: "Ocultar menú del filtro",
      jumpToPageDropdownLabel: "Ir al menú desplegable de página",
      jumpToPageInputLabel: "Ir a la entrada de página",
      lastPageLabel: "Última Página",
      listView: "Vista de lista",
      moveAllToSource: "Mover todo al origen",
      moveAllToTarget: "Mover todo al objetivo",
      moveBottom: "Desplazarse hacia abajo",
      moveDown: "Bajar",
      moveTop: "Mover arriba",
      moveToSource: "Mover al origen",
      moveToTarget: "Mover al objetivo",
      moveUp: "Subir",
      navigation: "Navegación",
      next: "Siguiente",
      nextPageLabel: "Siguiente Página",
      nullLabel: "No seleccionado",
      otpLabel: "Introduzca el carácter de contraseña de un solo uso {0}",
      pageLabel: "Página {page}",
      passwordHide: "Contraseña oculta",
      passwordShow: "Mostrar contraseña",
      previous: "Anterior",
      previousPageLabel: "Página Anterior",
      rotateLeft: "Girar a la izquierda",
      rotateRight: "Girar a la derecha",
      rowsPerPageLabel: "Filas por página",
      saveEdit: "Guardar editado",
      scrollTop: "Desplazarse hacia arriba",
      selectAll: "Seleccionar todos",
      selectLabel: "Seleccionar",
      selectRow: "Seleccionar fila",
      showFilterMenu: "Mostrar menú del filtro",
      slide: "Deslizar",
      slideNumber: "{slideNumber}",
      star: "1 estrella",
      stars: "{star} estrellas",
      trueLabel: "Verdadero",
      unselectAll: "Deseleccionar todos",
      unselectLabel: "Deseleccionar",
      unselectRow: "Desmarcar fila",
      zoomImage: "Ampliar imagen",
      zoomIn: "Ampliar",
      zoomOut: "Reducir",
    },
  },
});

import * as Sentry from "@sentry/vue";

const isDevelopment = import.meta.env.MODE === "development";
const isDemoEnv = import.meta.env.MODE === "demo";
// const isQaEnv = import.meta.env.MODE === "qa";
// const isPreEnv = import.meta.env.MODE === "prepro";
const isLocal = isDevelopment || isDemoEnv;

// Check if the environment is not local
if (!isLocal) {
  Sentry.init({
    app,
    dsn: "https://86bb2e86d0800ceee29b278cfeed612a@o4507896044847104.ingest.de.sentry.io/4507896140726352",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
      Sentry.browserProfilingIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      "localhost",
      /^https:\/\/qa\.gestor\.agrocontratacion\.com\//,
      /^https:\/\/demo\.gestor\.agrocontratacion\.com\//,
      /^https:\/\/pre\.gestor\.agrocontratacion\.com\//,
      /^https:\/\/gestor\.agrocontratacion\.com\//,
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    // Profiling
    profilesSampleRate: 1.0, // Profile 100% of the transactions. This value is relative to tracesSampleRate
    environment: import.meta.env.MODE,
  });
}

router.isReady().then(() => {
  app.mount("#app");
});

// Register the service worker with auto reload
import { registerSW } from "virtual:pwa-register";
registerSW({ immediate: true });
