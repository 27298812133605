import { createI18n } from "vue-i18n";

import strings from "@/lang/translations";

export default createI18n({
  locale: import.meta.env.VITE_DEFAULTLOCALE,
  fallbackLocale: import.meta.env.VITE_FALLBACKLOCALE,
  legacy: false,
  messages: strings,
});
