/* eslint-disable no-unused-vars */
import { App, URLOpenListenerEvent } from "@capacitor/app";
import { createRouter, createWebHistory } from "@ionic/vue-router";
import { RouteRecordRaw } from "vue-router";

const Login = () => import("./views/public/pages/Login.vue");
const RegisterChoose = () => import("./views/public/pages/RegisterChoose.vue");
const RegisterUser = () => import("./views/public/pages/RegisterUser.vue");
const RegisterCompanyForm = () =>
  import("./views/public/pages/RegisterCompanyForm.vue");
const RegisterRancher = () =>
  import("./views/public/pages/RancherRegister.vue");
const RegisterRancherEnterprise = () =>
  import("./views/public/pages/RancherRegisterEnterprise.vue");
const RegisterOperator = () =>
  import("./views/public/pages/OperatorRegister.vue");
const RegisterSuccess = () =>
  import("./views/public/pages/RegisterSuccess.vue");
const AccountValidation = () =>
  import("./views/public/pages/AccountValidation.vue");
const TermsAndConditions = () =>
  import("./views/public/pages/TermsAndConditions.vue");
const PrivacyPolicy = () => import("./views/public/pages/PrivacyPolicy.vue");

const TabsOperador = () => import("./views/operador/TabsOperador.vue");
const AnimalesOperador = () => import("./views/operador/Animales.vue");
const ContratosOperador = () => import("./views/operador/Contratos.vue");
const ContratoOperador = () => import("./views/operador/Contrato.vue");
const PerfilOperador = () => import("./views/operador/Perfil.vue");
const FeriaVirtualOperador = () =>
  import("./views/feria_virtual/FeriaVirtual.vue");
const AdvertiserProfileOperator = () =>
  import("./views/feria_virtual/AdvertiserProfile.vue");
const MyAdvertProposals = () =>
  import("./views/feria_virtual/MyAdvertProposals.vue");
const AdvertProposalViewOperator = () =>
  import("./views/feria_virtual/AdvertProposalView.vue");

const OfflineRanchersOperator = () =>
  import("./views/operador/offline/OfflineRanchersOperator.vue");
const OfflineRanchersCrudOperator = () =>
  import("./views/operador/offline/OfflineRanchersCrudOperator.vue");
const OfflineContractsOperator = () =>
  import("./views/operador/offline/OfflineContractsOperator.vue");
const NewOfflineContractOperator = () =>
  import("./views/operador/offline/NewOfflineContractOperator.vue");
const OfflineContractOperator = () =>
  import("./views/operador/offline/OfflineContractOperator.vue");
const TechniciansOperator = () =>
  import("./views/operador/technicians/TechniciansOperator.vue");
const TechniciansCrudOperator = () =>
  import("./views/operador/technicians/TechniciansCrud.vue");
const ConfiguracionOperador = () =>
  import("./views/operador/Configuracion.vue");

const TabsGanadero = () => import("./views/ganadero/TabsGanadero.vue");
const AnimalesGanadero = () => import("./views/ganadero/Animales.vue");
const NewAnimalRancherView = () => import("./views/ganadero/NewAnimal.vue");
const NewAnimalListRancherView = () =>
  import("./views/ganadero/NewAnimalList.vue");
const AnimalGanadero = () => import("./views/ganadero/Animal.vue");
const ContratosGanadero = () => import("./views/ganadero/Contratos.vue");
const ContratoGanadero = () => import("./views/ganadero/Contrato.vue");
const ContratoCompraGanadero = () =>
  import("./views/ganadero/ContratoCompra.vue");
const PerfilGanadero = () => import("./views/ganadero/Perfil.vue");
const MisExplotacionesGanadero = () =>
  import("./views/ganadero/MisExplotaciones.vue");
const ConfiguracionGanadero = () =>
  import("./views/ganadero/Configuracion.vue");
const FeriaVirtualGanadero = () =>
  import("./views/feria_virtual/FeriaVirtual.vue");
const NewAdvertRancherSwipe = () =>
  import("./views/feria_virtual/NewAdvertRancher.vue");
const MyAdvertRancher = () => import("./views/feria_virtual/MyAdvert.vue");
const AdvertiserProfileRancher = () =>
  import("./views/feria_virtual/AdvertiserProfile.vue");
const MyAdvertsRancher = () => import("./views/feria_virtual/MyAdverts.vue");
const AdvertProposalViewRancher = () =>
  import("./views/feria_virtual/AdvertProposalView.vue");

const PerfilTechnician = () => import("./views/technician/Perfil.vue");
const ContratosTechnician = () => import("./views/technician/Contratos.vue");
const FeriaVirtualTechnician = () =>
  import("./views/feria_virtual/FeriaVirtual.vue");

const AdvertView = () => import("./views/feria_virtual/AdvertView.vue");
const TemplatesView = () => import("./views/templates/Templates.vue");
const Rates = () => import("./views/rates/Rates.vue");
const RatesCrud = () => import("./views/rates/RatesCrud.vue");
const PublicQr = () => import("./views/public/pages/PublicQr.vue");
const PublicAdvertView = () =>
  import("./views/public/pages/PublicAdvertView.vue");

const NewContract = () => import("./views/NewContract.vue");
const Support = () => import("./views/SupportPage.vue");
const Contact = () => import("./views/ContactPage.vue");
const NotificationsPage = () => import("./views/NotificationsPage.vue");

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: Login,
  },
  {
    path: "/:lang",
    component: Login,
  },
  {
    path: "/:lang/login",
    name: "login",
    component: Login,
  },
  {
    path: "/:lang/register/choose",
    name: "registerChoose",
    component: RegisterChoose,
  },
  {
    path: "/:lang/register/user",
    name: "registerUser",
    component: RegisterUser,
  },
  {
    path: "/:lang/register/company",
    name: "registerCompany",
    component: RegisterCompanyForm,
  },
  {
    path: "/:lang/register/rancher",
    name: "registerRancher",
    component: RegisterRancher,
  },
  {
    path: "/:lang/register/rancherEnterprise",
    name: "registerRancherEnterprise",
    component: RegisterRancherEnterprise,
  },
  {
    path: "/:lang/register/operator",
    name: "register/operator",
    component: RegisterOperator,
  },
  {
    path: "/:lang/register/success",
    name: "registerSuccess",
    component: RegisterSuccess,
  },
  {
    path: "/:lang/termsAndConditions",
    name: "termsAndConditions",
    component: TermsAndConditions,
  },
  {
    path: "/:lang/privacyPolicy",
    name: "privacyPolicy",
    component: PrivacyPolicy,
  },
  {
    path: "/:lang/validate/:token",
    name: "validate",
    component: AccountValidation,
  },
  {
    path: "/:lang/public/qr/:animalId",
    name: "public-qr",
    component: PublicQr,
    props: true,
  },
  {
    path: "/:lang/public/feria/:advertId",
    name: "public-feria",
    component: PublicAdvertView,
    props: true,
  },

  {
    path: "/:lang/ganadero",
    component: TabsGanadero,
    meta: { requiresAuth: true },
    children: [
      {
        path: "animales",
        redirect: "/es/ganadero/animales/en-propiedad",
      },
      {
        path: "animales/:segment",
        name: "animales-ganadero",
        component: AnimalesGanadero,
        props: (route) => ({
          segment: route.params.segment ? route.params.segment : "en-propiedad",
        }),
      },
      {
        path: "animales/nuevo",
        name: "nuevo-animal-ganadero",
        component: NewAnimalRancherView,
      },
      {
        path: "animales/nuevo-listado",
        name: "nuevo-listado-ganadero",
        component: NewAnimalListRancherView,
      },
      {
        path: "animales/animal/:animalId",
        name: "animal-ganadero",
        component: AnimalGanadero,
        props: true,
      },
      {
        path: "contratos",
        redirect: "/es/ganadero/contratos/venta",
      },
      {
        path: "contratos/:segment",
        name: "contratos-ganadero",
        component: ContratosGanadero,
        props: (route) => ({
          segment: route.params.segment ? route.params.segment : "venta",
        }),
      },
      {
        path: "contratos/contrato/:contractId",
        name: "contrato-ganadero",
        component: ContratoGanadero,
        props: true,
      },
      {
        path: "contratos/compra/contrato/:contractId",
        name: "contrato-compra-ganadero",
        component: ContratoCompraGanadero,
        props: true,
      },
      {
        path: "contratos/nuevo",
        name: "nuevo-contrato-ganadero",
        component: NewContract,
      },
      {
        path: "contratos/templates",
        name: "templates-ganadero",
        component: TemplatesView,
      },
      {
        path: "contratos/rates",
        name: "rates-ganadero",
        component: Rates,
      },
      {
        path: "contratos/rates/crud/:rateId?",
        name: "rates-crud-ganadero",
        props: (route) => ({
          rateId: route.params.rateId ? route.params.rateId : undefined,
        }),
        component: RatesCrud,
      },
      {
        path: "perfil",
        name: "perfil-ganadero",
        component: PerfilGanadero,
      },
      {
        path: "mis-explotaciones",
        name: "mis-explotaciones-ganadero",
        component: MisExplotacionesGanadero,
      },
      {
        path: "configuracion",
        name: "configuracion-ganadero",
        component: ConfiguracionGanadero,
      },
      {
        path: "feria",
        name: "feria-virtual-ganadero",
        component: FeriaVirtualGanadero,
      },
      {
        path: "feria/nuevo-anuncio",
        name: "nuevo-anuncio-ganadero",
        component: NewAdvertRancherSwipe,
      },
      {
        path: "feria/anuncio/:advertId",
        name: "vista-anuncio-ganadero",
        component: AdvertView,
        props: true,
      },
      {
        path: "feria/mis-anuncios",
        name: "mis-anuncios-ganadero",
        component: MyAdvertsRancher,
      },
      {
        path: "feria/mi-anuncio/:advertId/:segment?",
        name: "vista-mi-anuncio-ganadero",
        component: MyAdvertRancher,
        props: (route) => ({
          advertId: route.params.advertId,
          segment: route.params.segment ? route.params.segment : undefined,
        }),
      },
      {
        path: "feria/anunciante/:userId",
        name: "vista-anunciante-ganadero",
        component: AdvertiserProfileRancher,
        props: true,
      },
      {
        path: "feria/propuestas/mis-propuestas",
        name: "mis-propuestas-ganadero",
        component: MyAdvertProposals,
      },
      {
        path: "feria/propuestas/:advertProposalId",
        name: "vista-propuesta-ganadero",
        component: AdvertProposalViewRancher,
        props: true,
      },
      {
        path: "contact",
        name: "contact-ganadero",
        component: Contact,
      },
      {
        path: "support",
        name: "support-ganadero",
        component: Support,
      },
    ],
  },
  {
    path: "/:lang/operador",
    component: TabsOperador,
    meta: { requiresAuth: true },
    children: [
      {
        path: "animales",
        name: "animales-operador",
        component: AnimalesOperador,
      },
      {
        path: "contratos",
        name: "contratos-operador",
        component: ContratosOperador,
      },
      {
        path: "contrato/:contractId",
        name: "contrato-operador",
        component: ContratoOperador,
        props: true,
      },
      {
        path: "contratos/nuevo",
        name: "nuevo-contrato-operador",
        component: NewContract,
      },
      {
        path: "contratos/offline",
        name: "offline-contracts-operator",
        component: OfflineContractsOperator,
      },
      {
        path: "contratos/offline/new",
        name: "new-offline-contract-operator",
        component: NewOfflineContractOperator,
      },
      {
        path: "contratos/offline/:contractId",
        name: "offline-contract-operator",
        props: (route) => ({
          contractId: route.params.contractId
            ? route.params.contractId
            : undefined,
        }),
        component: OfflineContractOperator,
      },
      {
        path: "contratos/offline/ranchers",
        name: "offline-ranchers-operator",
        component: OfflineRanchersOperator,
      },
      {
        path: "contratos/offline/ranchers/crud/:rancherId?",
        name: "offline-ranchers-crud-operator",
        props: (route) => ({
          rancherId: route.params.rancherId
            ? route.params.rancherId
            : undefined,
        }),
        component: OfflineRanchersCrudOperator,
      },
      {
        path: "contratos/rates",
        name: "rates-operator",
        component: Rates,
      },
      {
        path: "contratos/rates/crud/:rateId?",
        name: "rates-crud-operator",
        props: (route) => ({
          rateId: route.params.rateId ? route.params.rateId : undefined,
        }),
        component: RatesCrud,
      },
      {
        path: "contratos/templates",
        name: "templates-operator",
        component: TemplatesView,
      },
      {
        path: "contratos/technicians",
        name: "technicians-operator",
        component: TechniciansOperator,
      },
      {
        path: "contratos/technicians/crud/:technicianId?",
        name: "technicians-crud-operator",
        props: (route) => ({
          technicianId: route.params.technicianId
            ? route.params.technicianId
            : undefined,
        }),
        component: TechniciansCrudOperator,
      },
      {
        path: "perfil",
        name: "perfil-operador",
        component: PerfilOperador,
      },
      {
        path: "feria",
        name: "feria-virtual-operador",
        component: FeriaVirtualOperador,
      },
      {
        path: "feria/anuncio/:advertId",
        name: "vista-anuncio-operador",
        component: AdvertView,
        props: true,
      },
      {
        path: "feria/anunciante/:userId",
        name: "vista-anunciante-operador",
        component: AdvertiserProfileOperator,
        props: true,
      },
      {
        path: "feria/propuestas/mis-propuestas",
        name: "mis-propuestas-operador",
        component: MyAdvertProposals,
      },
      {
        path: "feria/propuestas/:advertProposalId",
        name: "vista-propuesta-operador",
        component: AdvertProposalViewOperator,
        props: true,
      },
      {
        path: "configuracion",
        name: "configuracion-operador",
        component: ConfiguracionOperador,
      },
      {
        path: "contact",
        name: "contact-operador",
        component: Contact,
      },
      {
        path: "support",
        name: "support-operador",
        component: Support,
      },
    ],
  },
  {
    path: "/notifications",
    name: "notifications",
    meta: { requiresAuth: true },
    component: NotificationsPage,
  },

  {
    path: "/:pathMatch(.*)*",
    redirect: "/",
  },
];

//   {
//     path: "/:lang/technician",
//     component: HomeTechnician,
//     children: [
//       {
//         path: "perfil",
//         name: "perfil-technician",
//         component: PerfilTechnician,
//         props: true,
//       },
//       {
//         path: "contratos",
//         name: "contratos-technician",
//         component: ContratosTechnician,
//       },
//       {
//         path: "feria-virtual",
//         name: "feria-virtual-technician",
//         component: FeriaVirtualTechnician,
//       },
//     ],
//   },
// ];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

export default router;

import { useAuthStore } from "./stores/AuthStore";
import { useUserStore } from "./stores/UserStore";

router.beforeEach((to, from, next) => {
  const authRequiredPaths = ["/operador", "/ganadero", "/technician"];
  const isAuthRequiredPath = authRequiredPaths.some((path) =>
    to.path.includes(path),
  );

  const authStore = useAuthStore();
  const userStore = useUserStore();

  const loginPaths = ["/", "/es", "/es/login"];
  const isLoginPath = loginPaths.some((path) => to.path === path);

  if (authStore.isLoggedIn && isLoginPath) {
    const rolePaths = {
      operador: "/es/operador/animales",
      ganadero: "/es/ganadero/animales",
      empresa_ganadera: "/es/ganadero/animales",
      technician: "/es/technician/contratos",
      partial: "/es/ganadero/animales",
    };

    let redirectPath = null;
    if (userStore.user.role)
      redirectPath =
        rolePaths[userStore.user.role.toLowerCase()] || "/es/login";

    if (to.path !== redirectPath) {
      next(redirectPath);
    } else {
      next();
    }
  } else if (isAuthRequiredPath && !authStore.isLoggedIn) {
    if (to.path !== "/es/login") {
      next("/es/login");
    } else {
      next();
    }
  } else {
    next();
  }
});

App.addListener("appUrlOpen", function (event: URLOpenListenerEvent) {
  const slug = event.url.split(".com").pop();

  // We only push to the route if there is a slug present
  if (slug) {
    router.push({
      path: slug,
    });
  }
});
